

export default {
    methods: {
        getOrdonnanceShutters(props) {
            return {
                'horse-selection': {
                    name: 'horse-selection',
                    title: this.$t('acte.choix_chevaux'),
                    cmpPath: 'components/Horse/HorseSelectionV2',
                    onOk: (horse_ids) => {
                        this.openOrdoAddForm(horse_ids)
                    },
                    props
                },
                'ordo-add-form': {
                    name: 'ordo-add-form',
                    title: this.$t('ordonnance.ajouter_ordonnance'),
                    cmpPath: 'components/Documents/OrdonnanceForm',
					onOk: (documents) => {
                        this.openOrdoRenameForm(documents, props.horses_ids)
                    },
                    props
                },
				'ordo-rename-form': {
                    name: 'ordo-rename-form',
                    title: this.$t('ordonnance.ajouter_ordonnance'),
                    cmpPath: 'components/Documents/OrdonnanceRenameForm',
                    onOk: this.onShuttersSubmit,
                    props
                },
                'ordo-edit': {
                    name: 'ordo-edit',
                    title: this.$t('ordonnance.modifier_ordonnance'),
                    cmpPath: 'components/Documents/OrdonnanceEdit',
                    onOk: this.onShuttersSubmit,
                    props
                }
            }
        },

        setupOrdoAdd(horse_id) {
            let horses_ids
            if(horse_id) horses_ids = horse_id.split(',')

            const shutters = this.getOrdonnanceShutters({
                horses_ids,
                without_horse: true
            })

            if(!horses_ids) {
                this.shutterPanel().open(shutters['horse-selection'])
            }
            else {
                this.shutterPanel().open(shutters['ordo-add-form'])
            }
        },

        openOrdoAddForm(horses_ids) {
            if (horses_ids) {
                const shutters = this.getOrdonnanceShutters({
                    horses_ids,
                    from_mediatheque: true
                })

                this.shutterPanel().open(shutters['ordo-add-form'])
            }
        },

		openOrdoRenameForm(documents, horses_ids) {
			const shutters = this.getOrdonnanceShutters({
				documents,
				horses_ids,
                from_mediatheque: true
			})

            this.shutterPanel().open(shutters['ordo-rename-form'])
        },

        setupOrdoEdit(document_id) {
            const shutters = this.getOrdonnanceShutters({
				document_id
            })
            this.shutterPanel().open(shutters['ordo-edit'])
        }
    }
}
