<template>
	<main>
		<HeaderTab :title="componentTitle" />
		
		<div id="content">
            <shutter-panel />
        </div>
	</main>
</template>

<script type="text/javascript">
	import Shutter from '@/mixins/Shutter.js'
    import ShutterOrdonnance from '@/mixins/shutters-manager/Ordonnance.js'

	export default {
		name: "ordonnanceManagement",
		mixins: [Shutter, ShutterOrdonnance],
		data () {
			return {
				from: null,
				componentTitle: ''
			}
		},
		mounted() {
			this.from = this.$route.params.from
			this.init_component()
		},
		methods: {
			init_component() {
                switch (this.usage) {
                    case 'edit':
                        this.setupOrdoEdit(this.documentId)
                        this.componentTitle = this.getTrad('ordonnance.modifier_ordonnance')
                        break
                    default:
                        this.setupOrdoAdd(this.horseIds)
                        this.componentTitle = this.getTrad('ordonnance.ajouter_ordonnance')
                        break
                }
            },
			onShuttersSubmit() {
				this.$router.push(this.urlAutoRedirect)
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab')
		},
		computed: {
			horseIds() {
				return this.$route.params.horse_id ? this.$route.params.horse_id.toString() : ''
			},
			documentId() {
				return this.$route.params.document_id
			},
			usage() {
				if(this.documentId) return 'edit'
				return 'add'
			},
			urlAutoRedirect() {
				if(this.from == 'HorseFicheOrdonnances')
					return { name: 'HorseFicheOrdonnances', params: { horse_id: this.horseIds }}
				else
					return { name: 'ordonnanceListe' }
			}
		}
	}
</script>
